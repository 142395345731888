<template>
    <div class="uk-grid uk-grid-medium" data-uk-grid>
        <div id="modal-insert" uk-modal>
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                 <div class="uk-margin ">
                    <label class="uk-form-label ">{{"Country" | localize}}</label>
                    <input class="uk-input" type="text" v-model='newCountry'>
                </div>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-margin-right uk-modal-close" type="button">{{"Close" | localize}}</button>
                    <button class="uk-button uk-button-primary" @click="insertCountries(newCountry)" type="button">{{"Save" | localize}}</button>
                </p>
            </div>
        </div>
        <!-- panel -->
        <div class="uk-width-1-1">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-expand" uk-grid>
                            <div class="uk-width-auto">
                                <a uk-icon="icon: plus; ratio: 1.3" class="uk-margin-small-top" @click="show()"></a>
                            </div>
                            <div class="uk-inline uk-width-expand">
                                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                                 <input class="uk-input uk-width-1-1 search-fld" type="text" v-model="val" :placeholder="localizeFilter('Search')" @input="filter(val)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <div class="form-container">
                        <ul class="uk-list uk-list-striped" v-for="(country, cnt) in countries"  :id="country.value">
                            <li><input class="uk-input uk-form-blank uk-form-width-large" type="text" :value="country.text" @blur="changeCountries(country, $event)"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getCountries from '@/config/GetCountries.general'
import localizeFilter from '@/filter/localize.filter'
import changeCountries from '@/config/ChangeCountries.dashboard'
import insertCountries from '@/config/InsertCountries.dashboard'
export default {
    data: () => ({
        countries: [],
        newCountry:'',
        val: '',
        allcountries: []
    }),
    methods:{
        getCountries, localizeFilter, changeCountries, insertCountries, 
        show(){
            UIkit.modal("#modal-insert").show()
        },
        filter(val){
            if(val === ''){
                this.countries = this.allcountries;
            }else{
                this.countries = this.allcountries;
                const filterValue = (name) => {
                    return this.countries.filter(data => {
                        return data.text.toLowerCase().indexOf(name.toLowerCase()) > -1;
                    });
                }
                this.countries = filterValue(val)
            }
        }
    },
    beforeMount(){
        this.getCountries()
    }
}
</script>